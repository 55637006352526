<template>
  <div class="page">登录中</div>
</template>

<script>
import axios from 'axios'
import Utils from '../../utils/utils'
// import router from '@/router/index.js'
import { baseUrl } from '@/components/config.js'

export default {
  name: 'Login_wechat',
  data () {
    return {
      type_str: ''
    }
  },
  mounted () {
    this.type_str = this.$route.query.type
    this.get_index()
  },
  methods: {
    get_index () {
      if (!Utils.check_is_niming()) {
        // this.$router.push('/')
        location.href = baseUrl
        return
      }
      axios.post('/login_by_wechat/', { code: this.$route.query.code, state: this.$route.query.state, launch_info: {} })
        .then(res => {
          if (res.status !== 200) {
            // this.$router.push('/')
            location.href = baseUrl
            return
          }
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          Utils.alert_success('登录成功')
          localStorage.setItem('token', res.data.data.token)
          // const routeData = this.$router.resolve({ path: '/' })
          // window.open(routeData.href)
          // this.$router.push('/')
          location.href = baseUrl
        })
    }
  }
}
</script>

<style scoped>
</style>
